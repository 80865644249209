import axios from 'axios'

export const postModule = {
    state: () => ({
        // Цвета________________
        mainColor: '#75baff',
        additionColor: '#75baff',

        negativeColorText: 'white',
        negativeColorTextHover: 'white',
        negativeColor: 'red',
        negativeColorHover: 'red',

        positiveColorText: 'black',
        positiveColorTextHover: 'white',
        positiveColor: '#b1ffb19e',
        positiveColorHover: 'red',

        gamePageColorMain: '#1e90ff',
        gamePageColorAdditional: '#75baff',
        // _______________________

        modalStatus: false,
        modalAccept: false,

        teams: [],

        settings: null,

        api: 'https://api.basketstat.ru/api.php?division=',

        teamID: '',
        teamInfo: [],
        playersTeam: [],

        modalEntry: false,

        globalAlerts: [],

        leaders: {},

        loading: true,

        loadingStatus: "Загрузка...",
        stopLoading: false,

        selectedSort: '',
        searchQuery: '',

        years: [],


        groups: [],
        yearActive: '',
        yearNow: '',

        division: 'A',
        divisions: [],

        stage: null, // Этап в дивизионе Object
        stages: [],

        scheduleOnline: [],
        scheduleFuture: [],
        schedulePast: [],

        gameTopHome: [],
        gameTopGuest: [],

        gameInfo: [],
        gameLog: [],
        gameOvertime: [],

        countPlayers: 0,
        gamePlayersHome: [],
        gamePlayersGuest: [],

        tableRegular: [],
        playerInfo: [],
        playerInfoActual: [],

        authorization: false,
        adminLogin: '',
        adminKey: '',

        loadingPlayers: false,
        addPlayersData: [],

        changePlayerNumFlag: false,
        changePlayerNumID: '',
        changePlayerNumPlayer: '',

        capTeam: false,
        capTeamAdmin: '',
        capDivision: '',
        capLogin: '',
        capKey: '',
    }),
    getters: {
        getGrouppsDivisionAndSeason(state) {
            return state.groups.filter((elem) => elem.division == state.division && elem.season == state.yearActive);
        },
        getStagesInYear(state) {
            if (!state.stages || !state.stages.length) return [];
            return state.stages.filter((elem) => elem.season == state.yearActive);
        },
        getStagesInDivision(state, getters) {
            if (!getters.getStagesInYear.length) return [];
            return getters.getStagesInYear.filter((elem) => elem.division == state.division);
        },
        getGroupsInYear(state) {
            if (!state.groups || !state.groups.length) return [];
            return state.groups.filter((elem) => elem.season == state.yearActive);
        },
        getGroupsInDivision(state, getters) {
            if (!getters.getGroupsInYear.length) return [];
            return getters.getGroupsInYear.filter((elem) => elem.division == state.division);
        },
        getGroupsInStage(state, getters) {
            if (!getters.getGroupsInDivision.length) return [];
            return getters.getGroupsInDivision.filter((elem) => elem.stage == state.stage.stage);
        },
        sortSearchPast(state) {
            return [...state.schedulePast].sort((team1, team2) =>
                team1[state.selectedSort]?.localeCompare(team2[state.selectedSort]),
            )
        },
        searchPast(state, getters) {
            return getters.sortSearchPast.filter(
                (elem) =>
                    elem.Команда1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Команда2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase())
            )
        },
        sortSearchOnline(state) {
            return [...state.scheduleOnline].sort((team1, team2) =>
                team1[state.selectedSort]?.localeCompare(team2[state.selectedSort]),
            )
        },
        searchOnline(state, getters) {
            return getters.sortSearchOnline.filter(
                (elem) =>
                    elem.Команда1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Команда2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase())
            )
        },
        sortSearchFuture(state) {
            return [...state.scheduleFuture].sort((team1, team2) =>
                team1[state.selectedSort]?.localeCompare(team2[state.selectedSort]),
            )
        },
        searchFuture(state, getters) {
            return getters.sortSearchFuture.filter(
                (elem) =>
                    elem.Команда1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Команда2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо1
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase()) ||
                    elem.Сокращённо2
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase())
            )
        },
    },
    mutations: {
        setSettings(state, settings) {
            state.settings = settings;
        },
        setStage(state, stage) {
            state.stage = stage;
        },
        setStages(state, stages) {
            state.stages = stages;
        },
        setGroups(state, groups) {
            state.groups = groups;
        },
        setStopLoading(state, bool) {
            state.stopLoading = bool;
        },
        setLoadingStatus(state, loadingStatus) {
            state.loadingStatus = loadingStatus;
        },
        setLoading(state, bool) {
            state.loading = bool;
        },

        setSelectedSort(state, selectedSort) {
            state.selectedSort = selectedSort
        },
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery
        },

        setGameTopHome(state, gameTopHome) {
            state.gameTopHome = gameTopHome
        },

        setGameTopGuest(state, gameTopGuest) {
            state.gameTopGuest = gameTopGuest
        },

        setAddPlayersData(state, addPlayersData) {
            state.addPlayersData = addPlayersData
        },
        setLoadingPlayers(state, loadingPlayers) {
            state.loadingPlayers = loadingPlayers
        },

        setTeams(state, teams) {
            state.teams = teams
        },

        setPlayersTeam(state, playersTeam) {
            state.playersTeam = playersTeam
        },
        setTeamInfo(state, teamInfo) {
            state.teamInfo = teamInfo
        },

        setLeaders(state, leaders) {
            state.leaders = leaders
        },

        setTeamID(state, teamID) {
            state.teamID = teamID
        },

        setPlayerInfo(state, playerInfo) {
            state.playerInfo = playerInfo
        },

        setDivision(state, division) {
            state.division = division;
        },

        setPlayerInfoActual(state, playerInfoActual) {
            state.playerInfoActual = playerInfoActual
        },

        setGameLog(state, gameLog) {
            state.gameLog = gameLog
        },
        setDivisions(state, divisions) {
            state.divisions = divisions
        },
        setYears(state, years) {
            state.years = years
        },
        setYearsActive(state, yearActive) {
            state.yearActive = yearActive
        },
        setYearsActiveFirst(state, yearActive) {
            state.yearActive = yearActive
        },
        setYearsNow(state, yearNow) {
            state.yearNow = yearNow
        },

        setScheduleOnline(state, scheduleOnline) {
            state.scheduleOnline = scheduleOnline
        },
        setScheduleFuture(state, scheduleFuture) {
            state.scheduleFuture = scheduleFuture
        },
        setSchedulePast(state, schedulePast) {
            state.schedulePast = schedulePast
        },

        setGameInfo(state, gameInfo) {
            state.gameInfo = gameInfo
        },
        setGameOvertime(state, gameOvertime) {
            state.gameOvertime = gameOvertime
        },

        setOpenModalAccept(state, modalAccept) {
            state.modalAccept = modalAccept
        },

        setTableRegular(state, tableRegular) {
            state.tableRegular = tableRegular
        },
        setErrorEntry(state, errorEntry) {
            state.errorEntry = errorEntry
        },
        setGamePlayersHome(state, gamePlayersHome) {
            state.gamePlayersHome = gamePlayersHome
        },
        setGamePlayersGuest(state, gamePlayersGuest) {
            state.gamePlayersGuest = gamePlayersGuest
        },

        setAuthorization(state, authorization) {
            state.authorization = authorization
        },
        setAdminKey(state, adminKey) {
            state.adminKey = adminKey
        },
        setAdminLogin(state, adminLogin) {
            state.adminLogin = adminLogin
        },

        setModalStatus(state, modalStatus) {
            state.modalStatus = modalStatus
        },

        setGlobalAlerts(state, globalAlerts) {
            for (let i in state.globalAlerts) {
                if (state.globalAlerts[i].id == globalAlerts.id) {
                    state.globalAlerts.splice(i, 1);
                    break;
                }
            }
            state.globalAlerts.push(globalAlerts);
        },
        deleteGlobalAlert(state, alertID) {
            for (let i in state.globalAlerts) {
                if (state.globalAlerts[i].id == alertID) {
                    state.globalAlerts.splice(i, 1);
                    break;
                }
            }
        },

        setCapTeam(state, capTeam) {
            state.capTeam = capTeam
        },
        setCapTeamAdmin(state, capTeamAdmin) {
            state.capTeamAdmin = capTeamAdmin
        },
        setCapDivision(state, capDivision) {
            state.capDivision = capDivision
        },
        setCapLogin(state, capLogin) {
            state.capLogin = capLogin
        },
        setCapKey(state, capKey) {
            state.capKey = capKey
        },


        setFlagChangePlayerNum(state, flag) {
            state.changePlayerNumFlag = flag
        },
        setChangePlayerNumID(state, playerID) {
            state.changePlayerNumID = playerID
        },
        setChangePlayerNumPlayer(state, player) {
            state.changePlayerNumPlayer = player
        },
    },
    actions: {
        async getTeams({ state, commit }) {

            commit('setTeams', []);

            // Проверяем существование такого сочетания дивизиона и сезона
            for (let i in state.divisions) {
                if (state.divisions[i].СезонБД == state.yearActive && state.divisions[i].Дивизион == state.division) {
                    try {
                        const response = await axios.post(
                            state.api + state.division,
                            {
                                params: {
                                    request: 'getTeams',
                                    year: state.yearActive,
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                                },
                            },
                        )

                        if (response.data != null) {
                            commit('setTeams', response.data);
                        } else {
                            commit('setTeams', []);
                        }

                        return true;
                    } catch (e) {
                        console.log(e);
                        return false;
                    }
                }
            }

            return true;
        },
        async requestSendData({ commit, state }, obj) { // Функция для изменения данных в базе данных

            // ВАЖНО ПЕРЕДАТЬ division параметр в obj.data, иначе запросы пойду по дивизиону в state

            // obj - объект, в котором для запроса нам нужно:
            // action - действие
            // data - передаваемые данные
            // success - сообщение, если успешно
            // error - сообщение об ошибке

            const alertID = Math.floor(Math.random() * 300);

            const division = obj.data.division ? obj.data.division : state.division

            try {
                const response = await axios.post(
                    state.api + division,
                    {
                        params: {
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            request: obj.action,
                            data: obj.data,
                            year: obj.season ? obj.season : state.yearNow, // Если есть в параметрах сезон, то передаем его, иначе берём активный
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data && response.data.status == "success") {
                    commit('setGlobalAlerts', { type: 'success', text: obj.success, id: alertID })
                    setTimeout(() => {
                        commit('deleteGlobalAlert', alertID)
                    }, "5000");

                    return true;

                } else {
                    commit('setGlobalAlerts', { type: 'error', text: response.data.text ? response.data.text : obj.error, id: alertID })
                    setTimeout(() => {
                        commit('deleteGlobalAlert', alertID)
                    }, "5000");
                }

                return false;

            } catch (e) {
                console.log(e);
                commit('setGlobalAlerts', { type: 'error', text: obj.error, id: alertID })
                setTimeout(() => {
                    commit('deleteGlobalAlert', alertID)
                }, "5000");
                return false;
            }
        },
        async requestGetData({ commit, state }, obj) { // Функция для получения данных

            // obj - объект, в котором для запроса нам нужно:
            // action - действие
            // data - передаваемые данные
            // error - сообщение об ошибке

            const alertID = Math.floor(Math.random() * 300);

            try {
                const response = await axios.post(
                    state.api + state.division,
                    {
                        params: {
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            request: obj.action,
                            data: obj.data ? obj.data : null,
                            year: obj.season ? obj.season : state.yearNow, // Если есть в параметрах сезон, то передаем его, иначе берём активный
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data && response.data.status == "success") {
                    return response.data.data;
                } else {
                    commit('setGlobalAlerts', { type: 'error', text: response.data.text ? response.data.text : obj.error, id: alertID })
                    setTimeout(() => {
                        commit('deleteGlobalAlert', alertID)
                    }, "5000");
                }
                return false;
            } catch (e) {
                console.log(e);
                commit('setGlobalAlerts', { type: 'error', text: obj.error, id: alertID })
                setTimeout(() => {
                    commit('deleteGlobalAlert', alertID)
                }, "5000");
                return false;
            }
        },
        async getTopPlayersGame({ commit, state }, obj) {
            try {
                setTimeout(async () => {
                    const response = await axios.post(
                        state.api + obj.division,
                        {
                            params: {
                                request: 'getTopPlayersGame',
                                year: obj.year,
                                gameID: obj.gameID,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                            },
                        },
                    )
                    commit('setGameTopHome', response.data.Home)
                    commit('setGameTopGuest', response.data.Guest)
                }, 0)
            } catch (e) {
                console.log(e)
            }
        },
        async getPlayersTeam({ commit, state }, obj) {
            try {

                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'getPlayersTeam',
                            year: obj.year,
                            teamID: obj.teamID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                if (response.data != null) {
                    commit('setPlayersTeam', response.data);
                    commit('setTeamID', obj.teamID);
                } else {
                    commit('setPlayersTeam', '');
                    commit('setTeamID', obj.teamID);
                }

                return true;

            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async getTeamInfo({ commit, state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'getTeamInfo',
                            year: obj.year,
                            teamID: obj.ID
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data == null) {
                    return false;
                } else {
                    commit('setTeamInfo', response.data[0]);
                    commit('setTeamID', obj.ID);
                    return true;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async getSettings({ commit, state }) {
            try {
                const response = await axios.post(
                    state.api + 'A',
                    {
                        params: {
                            request: 'getSettings',
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                commit('setSettings', response.data)

                if (response.data != null) {
                    commit('setYearsNow', response.data.ТекущийСезон);
                    commit('setYearsActiveFirst', response.data.ТекущийСезон);
                    return response.data;
                } else {
                    return false;
                }

            } catch (e) {
                console.log(e)
            }
        },
        async getYears({ commit, state }) {
            try {
                const response = await axios.post(
                    state.api + 'A',
                    {
                        params: {
                            request: 'getYears',
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data != null) {
                    commit('setYears', response.data[1])
                    for (let i in response.data[3]) {
                        if (response.data[3][i].division == state.division && state.settings.stage == response.data[3][i].stage && state.yearActive == response.data[3][i].season) {
                            commit('setStage', response.data[3][i])
                        }
                    }
                    commit('setDivisions', response.data[2])
                    commit('setStages', response.data[3])
                    commit('setGroups', response.data[4])
                    return true;
                } else {
                    return false;
                }

            } catch (e) {
                console.log(e)
            }
        },
        async getRecentMatches({ state, commit }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'getRecentMatches',
                            year: obj.year,
                            teamID: obj.teamID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                return response.data;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async getSchedule({ state, commit }) {

            commit('setScheduleOnline', '')
            commit('setScheduleFuture', '')
            commit('setSchedulePast', '')

            // Проверяем существование такого сочетания дивизиона и сезона
            for (let i in state.divisions) {
                if (state.divisions[i].СезонБД == state.yearActive && state.divisions[i].Дивизион == state.division) {
                    try {
                        const response = await axios.post(
                            state.api + state.division,
                            {
                                params: {
                                    request: 'getSchedule',
                                    year: state.yearActive,
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                                },
                            },
                        )

                        if (response.data != null) {
                            if (response.data[0]) {
                                commit('setScheduleOnline', response.data[0])
                            } else {
                                commit('setScheduleOnline', '')
                            }

                            if (response.data[1]) {
                                commit('setScheduleFuture', response.data[1])
                            } else {
                                commit('setScheduleFuture', '')
                            }

                            if (response.data[2]) {
                                commit('setSchedulePast', response.data[2])
                            } else {
                                commit('setSchedulePast', '')
                            }
                        } else if (response.data == null) {
                            commit('setScheduleOnline', '')
                            commit('setScheduleFuture', '')
                            commit('setSchedulePast', '')
                        }

                        return true;

                    } catch (e) {
                        console.log(e);
                        return false;
                    }
                }
            }

            return true;

        },
        async getMatchInfo({ commit, state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'getMatchInfo',
                            year: obj.year,
                            gameID: obj.gameID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data != null) {
                    commit('setGameInfo', response.data[0])
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e)
                return false;
            }
        },
        async getMatchPlayers({ commit, state }, gameData) {
            try {
                const response = await axios.post(
                    state.api + gameData.division,
                    {
                        params: {
                            request: 'getMatchPlayers',
                            year: gameData.year,
                            gameID: gameData.gameID,
                            IDHome: gameData.IDHome,
                            IDGuest: gameData.IDGuest,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data != null) {
                    commit('setGamePlayersHome', response.data[0])
                    commit('setGamePlayersGuest', response.data[1])
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e)
            }
        },
        async getAddPlayers({ commit, state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            request: 'getAddPlayers',
                            year: obj.year,
                            gameID: obj.gameID,
                            teamID: obj.teamID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                commit('setAddPlayersData', response.data[1]);

                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async pushPlayerInGame({ state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'pushPlayerInGame',
                            year: obj.year,
                            gameID: obj.gameID,
                            playerNum: obj.playerNum,
                            playerID: obj.playerID,
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async getMatchComprasion({ commit, state }, gameData) {
            try {
                const response = await axios.post(
                    state.api + gameData.division,
                    {
                        params: {
                            request: 'getMatchComprasion',
                            year: gameData.year,
                            IDHome: gameData.IDHome,
                            IDGuest: gameData.IDGuest,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                if (response.data != null) {
                    commit('setGamePlayersHome', response.data[0]);
                    commit('setGamePlayersGuest', response.data[1]);
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e)
                return false;
            }
        },

        async getGameLog({ commit, state }, gameData) {
            try {
                const response = await axios.post(
                    state.api + gameData.division,
                    {
                        params: {
                            request: 'getGameLog',
                            year: gameData.year,
                            gameID: gameData.gameID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data != null) {
                    commit('setGameLog', response.data);
                    return true;
                } else {
                    return false;
                }

            } catch (e) {
                console.log(e)
                return false;
            }
        },

        async getLeaders({ state, commit }) {

            commit('setLeaders', []);

            // Проверяем существование такого сочетания дивизиона и сезона
            for (let i in state.divisions) {
                if (state.divisions[i].СезонБД == state.yearActive && state.divisions[i].Дивизион == state.division) {
                    try {
                        const response = await axios.post(
                            state.api + state.division,
                            {
                                params: {
                                    request: 'getLeaders',
                                    year: state.yearActive,
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                                },
                            },
                        )

                        if (response.data) {
                            commit('setLeaders', response.data);
                        }

                        return true;

                    } catch (e) {
                        console.log(e);
                        return false;
                    }
                }
            }

            return true;
        },

        async subscribeBot({ state }, object) {
            let table = window.location.host.split('.')[0] + object.division;
            //if (table.indexOf("localhost") >= 0) table = "slproC"; // УБРАТЬ НА ПРОДЕ
            let url = "https://t.me/bs_alert_bot?start=" + object.type + "=" + object.ID + "=" + table + "=" + state.yearNow;
            //  https://t.me/bs_alert_bot?start=team=9=slproC=2022_2023
            window.open(url, '_blank').focus();
        },

        async getTableRegular({ state, commit }) {
            try {
                const response = await axios.post(
                    state.api + state.division,
                    {
                        params: {
                            request: 'getTableRegular',
                            year: state.yearActive,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data == null) {
                    commit('setTableRegular', '');
                } else {
                    commit('setTableRegular', response.data);
                }

                return true;

            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async getPlayerInfo({ commit, state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'getPlayerInfo',
                            year: obj.year,
                            playerID: obj.playerID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data == null) {
                    return false;
                } else {
                    commit('setPlayerInfo', response.data);
                    commit('setPlayerInfoActual', response.data[0]);
                    commit('setTeamID', response.data[0].teamID);
                    return true;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async entry({ state, commit }, obj) {

            let accept;
            if (obj.accept) {
                accept = true;
            } else {
                accept = false;
            }

            commit('setAuthorization', false);
            commit('setAdminKey', '');
            commit('setAdminLogin', '');

            commit('setCapTeam', false);
            commit('setCapTeamAdmin', '');
            commit('setCapDivision', '');
            commit('setCapLogin', '');
            commit('setCapKey', '');

            localStorage.admin = '';
            localStorage.adminKey = '';

            localStorage.capLogin = '';
            localStorage.capKey = '';

            try {
                const response = await axios.post(
                    state.api + state.division,
                    {
                        params: {
                            request: 'entry',
                            login: obj.login,
                            password: obj.password,
                            accept: accept
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )

                if (response.data[0].status == 'success') {

                    if (response.data[0].cap) {
                        commit('setCapTeam', true);
                        commit('setCapTeamAdmin', response.data[0].teamID);
                        commit('setCapDivision', response.data[0].capDivision);
                        commit('setCapKey', response.data[0].key);
                        commit('setCapLogin', obj.login);

                        localStorage.capLogin = obj.login;
                        localStorage.capKey = response.data[0].key;

                        if (response.data[0].teamID !== 'Модератор' && response.data[0].teamID !== 'Админ') {
                            return { status: 'success', route: response.data[0].capDivision + '/' + response.data[0].season + '/team/' + response.data[0].teamID }
                        } else {
                            return { status: 'success' }
                        }
                    } else {
                        commit('setAuthorization', true);
                        commit('setAdminKey', response.data[0].key);
                        commit('setAdminLogin', obj.login);

                        localStorage.admin = obj.login;
                        localStorage.adminKey = response.data[0].key;
                    }

                    return { status: 'success' };
                } else if (response.data[0].status == 'accept') {
                    return response.data[0];
                } else if (response.data[0].status == 'error') {
                    return { status: 'error' };
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async checkEntry({ commit, state }, localStorage) {
            if (localStorage.capKey) {
                try {
                    const response = await axios.post(
                        state.api + 'A',
                        {
                            params: {
                                request: 'checkEntry',
                                login: localStorage.capLogin,
                                adminKey: localStorage.capKey,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                            },
                        },
                    )
                    if (response.data[0].status == 'success') {
                        if (response.data[0].cap) {
                            commit('setCapTeam', true);
                            commit('setCapTeamAdmin', response.data[0].teamID);
                            commit('setCapDivision', response.data[0].capDivision);
                            commit('setCapLogin', response.data[0].login);
                            commit('setCapKey', response.data[0].key);
                        } else {
                            commit('setAuthorization', true);
                            commit('setAdminKey', response.data[0].key);
                            commit('setAdminLogin', response.data[0].login);
                        }
                    } else if (response.data[0].status == 'error') {
                        commit('setAuthorization', false);
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            } else {
                try {
                    const response = await axios.post(
                        state.api + 'A',
                        {
                            params: {
                                request: 'checkEntry',
                                login: localStorage.admin,
                                adminKey: localStorage.adminKey,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                            },
                        },
                    )
                    if (response.data[0].status == 'success') {
                        commit('setAuthorization', true);
                        commit('setAdminKey', response.data[0].key);
                        commit('setAdminLogin', response.data[0].login);
                    } else if (response.data[0].status == 'error') {
                        commit('setAuthorization', false);
                    }
                    return true;
                } catch (e) {
                    console.log(e);
                    return false;
                }
            }
        },

        async sendRequest({ commit, state }, obj) {
            try {
                if (state.settings.Аккаунты) {
                    commit('setAuthorization', false);
                    commit('setAdminKey', '');
                    commit('setAdminLogin', '');

                    commit('setCapTeam', false);
                    commit('setCapTeamAdmin', '');
                    commit('setCapDivision', '');
                    commit('setCapLogin', '');
                    commit('setCapKey', '');
                    localStorage.admin = '';
                    localStorage.adminKey = '';

                    localStorage.capLogin = '';
                    localStorage.capKey = '';
                }

                commit('setLoading', true)
                let teamData = new FormData()

                teamData.append('logo', obj.teamInfo.teamLogo)

                for (var i = 0; i < obj.teamPlayers.playerPhoto.length; i++) {
                    if (
                        obj.teamPlayers.playerPhoto[i] == 'undefined' ||
                        obj.teamPlayers.playerPhoto[i] == '' ||
                        obj.teamPlayers.playerPhoto[i] == null
                    ) {
                    } else {
                        let file = obj.teamPlayers.playerPhoto[i]
                        teamData.append('players[' + i + ']', file)
                    }
                }

                teamData.append('teamData', JSON.stringify(obj))

                const response = await axios.post(
                    state.api +
                    obj.teamInfo.division +
                    '&regTeam',
                    teamData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )

                if (response.data[0].status == 'success') {
                    return response.data[0];
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendAddPlayer({ state }, obj) {
            try {
                let playerData = new FormData()

                playerData.append('playerPhoto', obj.newPlayerPhoto)
                playerData.append('adminData', JSON.stringify(localStorage))
                playerData.append('playerData', JSON.stringify(obj))

                const response = await axios.post(
                    state.api +
                    obj.teamDivision +
                    '&addPlayer',
                    playerData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return response.data[0].status;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendChangeTeamName({ state }, obj) {
            try {
                let teamData = new FormData()

                teamData.append('adminData', JSON.stringify(localStorage))
                teamData.append('teamData', JSON.stringify(obj))

                const response = await axios.post(
                    state.api +
                    obj.division +
                    '&changeTeamName',
                    teamData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )

                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendChangePlayerPhoto({ state }, obj) {
            try {
                const playerData = new FormData()

                playerData.append('newPhoto', obj.newPhoto)
                playerData.append('adminData', JSON.stringify(localStorage))
                playerData.append('playerData', JSON.stringify(obj))

                const response = await axios.post(
                    state.api +
                    obj.division +
                    '&changePlayerPhoto',
                    playerData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )

                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendChangeTeamLogo({ state }, obj) {
            try {
                let teamData = new FormData()

                teamData.append('teamLogo', obj.newLogo)
                teamData.append('adminData', JSON.stringify(localStorage))
                teamData.append('teamData', JSON.stringify(obj))

                const response = await axios.post(
                    state.api +
                    obj.division +
                    '&changeTeamLogo',
                    teamData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )

                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendChangePlayerInfo({ state }, obj) {
            try {
                let playerData = new FormData();
                playerData.append('playerPhoto', obj.newPlayerPhoto);
                playerData.append('adminData', JSON.stringify(localStorage));
                playerData.append('playerData', JSON.stringify(obj));

                const response = await axios.post(
                    state.api +
                    obj.teamDivision +
                    '&changePlayerInfo',
                    playerData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        async sendDeleteGame({ state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'deleteGame',
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            gameID: obj.gameID,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                if (response.data[0].status == 'success') {
                    return true;
                } else if (response.data[0].status == 'error') {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendAddGame({ state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'addGame',
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            gameData: obj,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                if (response.data[0].status == 'success') {
                    return response.data[0].gameID;
                } else if (response.data[0].status == 'error') {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async sendStartGame({ state }, obj) {
            try {
                const response = await axios.post(
                    state.api + obj.division,
                    {
                        params: {
                            request: 'startGame',
                            adminData: {
                                admin: localStorage.admin,
                                adminKey: localStorage.adminKey,
                                moder: localStorage.capLogin,
                                moderKey: localStorage.capKey,
                            },
                            gameData: obj,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                        },
                    },
                )
                if (response.data[0].status == 'success') {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async exit({ commit }) {
            try {
                commit('setLoading', true);

                commit('setAuthorization', false);
                commit('setAdminKey', '');
                commit('setAdminLogin', '');

                commit('setCapTeam', false);
                commit('setCapTeamAdmin', '');
                commit('setCapDivision', '');
                commit('setCapLogin', '');
                commit('setCapKey', '');

                localStorage.admin = '';
                localStorage.adminKey = '';

                localStorage.capLogin = '';
                localStorage.capKey = '';

                setTimeout(() => {
                    commit('setLoading', false)
                }, 1000)
            } catch (e) {
                console.log(e);
                return false;
            }
        },

        async openModalEntry({ commit }) {
            try {
                commit('setModalStatus', true)
            } catch (e) {
                console.log(e)
            }
        },
    },
    namespaced: true,
}
