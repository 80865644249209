<template>
  <Menu></Menu>
  <div class="app" :class="{ gamePage: this.$route.name == 'GamePage' }">

    <div v-if="mainLoading" class="global-loading">
      <LoadingDownload />
    </div>


    <div class="alert" :class="{ noAlerts: !this.globalAlerts.length }">
      <transition-group name="slide-fade">
        <div v-for="alert in this.globalAlerts" :key="alert.id" :class="`${alert.type}`"
          @click="this.deleteGlobalAlert(alert.id)">
          <span>{{ alert.text }}</span>
        </div>
      </transition-group>
    </div>


    <transition name="fade">
      <ModalEntry v-model:show="modalStatus">
        <div v-if="!loadingEntry && !confirmation">
          <h3>Вход</h3>
          <MainInput v-model.trim="login" placeholder="Login"></MainInput>
          <MainInput v-model.trim="password" type="password" placeholder="Password"></MainInput>
          <MainButton @click="attemptLogin(login, password)" class="btn-entry">Вход</MainButton>

          <h6 class="error" v-if="message">{{ message }}</h6>
        </div>

        <div v-else-if="!loadingEntry && confirmation">
          <h3 style="text-align: center;"> {{ message }}</h3>
          <MainButton @click="attemptLogin(login, password, 'accept')" class="btn-entry">Подтверждаю</MainButton>
          <MainButton style="margin-top: 10px;" @click="cancelEntry()" class="btn-entry">Отмена</MainButton>
        </div>

        <div v-else class="loading-entry">
          <img src="@/img/download.gif" alt="Загрузка">
          {{ message }}
        </div>

      </ModalEntry>
    </transition>

    <router-view v-if="successFirstLoading" v-slot="{ Component }">

      <transition name="fade">
        <div class="schedule_row" id="schedule_row"
          v-if="schedule_row && this.$route.name !== 'GamePage' && this.$route.name !== 'RequestPage' && this.$route.name !== 'GameStartPage' && !load_matches">
          <div @click="$router.push(`/${this.division}/${this.yearActive}/game/${elem.ID}`)" class="schedule_row_match"
            v-for="elem in schedule_row" :class="{ match_online: elem.Статус == 1 }" :key="elem.ID">
            <div class="schedule_row_match_score"
              :class="{ team_winner: elem.Счёт1 > elem.Счёт2, team_loser: elem.Счёт1 < elem.Счёт2 }">
              <span>{{ elem.Счёт1 }}</span>
            </div>
            <div class="img_circle">
              <div class="schedule_row_match_img">
                <div class="schedule_row_match_img_elem">
                  <img :src="`https://api.basketstat.ru/files/teams/${elem.Логотип}`" alt="Логотип команды">
                </div>
                <div class="schedule_row_match_img_elem">
                  <img :src="`https://api.basketstat.ru/files/teams/${elem.Логотип2}`" alt="Логотип команды">
                </div>
              </div>
            </div>
            <div class="schedule_row_match_score"
              :class="{ team_winner: elem.Счёт2 > elem.Счёт1, team_loser: elem.Счёт1 > elem.Счёт2 }">
              <span>{{ elem.Счёт2 }}</span>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="schedule_row load_matches" v-if="load_matches">
          <img src="@/img/download.gif" alt="Загрузка">
        </div>
      </transition>

      <component :is="Component" v-if="this.stage && this.division && this.yearActive"></component>

    </router-view>

  </div>
  <Footer></Footer>
</template>
<script>
import Menu from "@/components/UI/Menu.vue";
import Footer from "@/components/UI/Footer.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";
import MainButton from "@/components/UI/MainButton";

export default {
  components: {
    MainButton,
    Menu,
    Footer,
  },
  data() {
    return {
      login: '',
      password: '',

      successFirstLoading: false,
      mainLoading: true,

      message: '',
      loadingEntry: false,
      confirmation: false,

      schedule_row: null,
      load_matches: true,

      scrollLeftMetches: 0,
    }
  },
  methods: {
    cancelEntry() {
      this.login = '';
      this.password = '';
      this.message = '';
      this.loadingEntry = false;
      this.confirmation = false;
    },
    async getLastMatches() {
      // Проверяем существование такого сочетания дивизиона и сезона
      for (let i in this.divisions) {
        if (this.divisions[i].СезонБД == this.yearActive && this.divisions[i].Дивизион == this.division) {
          try {
            this.load_matches = true;
            const response = await axios.post(
              this.api + this.division,
              {
                params: {
                  request: 'getLastMatches',
                  year: this.yearActive,
                },
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                },
              },
            )
            return response.data;
          } catch (e) {
            console.log(e);
            return false;
          }
        }
      }

    },


    async attemptLogin(login, password, accept = false) {

      this.loadingEntry = true;
      this.message = "Попытка входа";

      await this.entry({ login, password, accept })
        .then(res => {
          if (!res) throw "Ошибка при попытке войти в аккаунт";
          if (res.status == "error") {
            this.loadingEntry = false;
            this.message = "Неверный логин или пароль";
          } else if (res.status === 'success') {
            this.loadingEntry = false;
            this.message = '';
            this.confirmation = false;
            this.setOpenModalAccept(false);
            this.setModalStatus(false);

            if (res.route) {
              this.$router.push('/' + res.route);
            }
          } else {
            if (res.status == "accept") {
              this.loadingEntry = false;
              this.confirmation = true;
              this.message = this.login + " уже ведёт статистику на матче " + res.teams;
            } else {
              throw "Ошибка при попытке войти в аккаунт";
            }
          }
        })
        .catch(err => {
          this.loadingEntry = false;
          this.message = err + ". Попробуйте позже.";
        });

    },
    ...mapMutations({
      setOpenModalAccept: 'post/setOpenModalAccept',
      deleteGlobalAlert: 'post/deleteGlobalAlert',

      setModalStatus: 'post/setModalStatus',

      setLoading: 'post/setLoading',
      setLoadingStatus: 'post/setLoadingStatus',
      setStopLoading: 'post/setStopLoading',
    }),
    ...mapActions({
      entry: 'post/entry',
      checkEntry: 'post/checkEntry',
      exit: 'post/exit',
      getYears: 'post/getYears',
      getSettings: 'post/getSettings',
    }),

  },
  computed: {
    ...mapState({

      divisions: state => state.post.divisions,
      stage: state => state.post.stage,
      loadingStatus: state => state.post.loadingStatus,
      stopLoading: state => state.post.stopLoading,
      division: state => state.post.division,
      yearActive: state => state.post.yearActive,
      api: state => state.post.api,

      authorization: state => state.post.authorization,
      errorEntry: state => state.post.errorEntry,
      modalStatus: state => state.post.modalStatus,

      globalAlerts: state => state.post.globalAlerts,

      // Цвета________________
      mainColor: state => state.post.mainColor,
      additionColor: state => state.post.additionColor,

      negativeColor: state => state.post.negativeColor,
      negativeColorHover: state => state.post.negativeColorHover,
      negativeColorTextHover: state => state.post.negativeColorTextHover,
      negativeColorText: state => state.post.negativeColorText,

      positiveColor: state => state.post.positiveColor,
      positiveColorHover: state => state.post.positiveColorHover,
      positiveColorTextHover: state => state.post.positiveColorTextHover,
      positiveColorText: state => state.post.positiveColorText,

      gamePageColorMain: state => state.post.gamePageColorMain,
      gamePageColorAdditional: state => state.post.gamePageColorAdditional,

      // _______________________
    }),

  },
  mounted() {
    (async () => {
      this.setLoadingStatus("Получаем настройки турнира");
      await this.getSettings()
        .then(res => {
          if (!res) throw "Ошибка при получении настроек турнира";

          // Устанавливаем цвета из Базы Данных
          const style = document.documentElement.style;
          style.setProperty('--mainColor', res.color1);
          style.setProperty('--additionColor', res.color2);
          style.setProperty('--negativeColor', this.negativeColor);
          style.setProperty('--negativeColorHover', this.negativeColorHover);
          style.setProperty('--negativeColorText', this.negativeColorText);
          style.setProperty('--negativeColorTextHover', this.negativeColorTextHover);

          style.setProperty('--positiveColor', this.positiveColor);
          style.setProperty('--positiveColorHover', this.positiveColorHover);
          style.setProperty('--positiveColorText', this.positiveColorText);
          style.setProperty('--positiveColorTextHover', this.positiveColorTextHover);

          style.setProperty('--gamePageColorMain', this.gamePageColorMain);
          style.setProperty('--gamePageColorAdditional', this.gamePageColorAdditional);

          console.log("v" + res.Версия);

          if (res.Версия != localStorage.ver) {
            localStorage.ver = res.Версия;
            window.location.reload();
          }
        })
        .catch(err => {
          this.setStopLoading(true);
          let errText = err + ". Перезагрузите страницу или попробуйте позже.";
          this.setLoadingStatus(errText);
        });
      this.setLoadingStatus("Грузим сезоны");
      await this.getYears()
        .then(res => {
          if (!res) throw "Ошибка получения игровых сезонов";
          this.successFirstLoading = true;
          this.mainLoading = false;

          if (((localStorage.admin != null && localStorage.admin != '') && (localStorage.adminKey != null && localStorage.adminKey != '')) || ((localStorage.capLogin != null && localStorage.capLogin != '') && (localStorage.capKey != null && localStorage.capKey != ''))) {
            return this.checkEntry(localStorage);
          } else {
            return true;
          }
        })
        .then(res => {
          if (res === false) throw "Ошибка при проверке авторизации";
          return this.getLastMatches();
        })
        .then(res => {
          this.schedule_row = res;
          this.load_matches = false;
        })
        .catch(err => {
          this.setStopLoading(true);
          let errText = err + ". Перезагрузите страницу или попробуйте позже.";
          this.setLoadingStatus(errText);
        });

    })();
  },
  watch: {
    division(newVal) {
      this.getLastMatches()
        .then(res => {
          this.schedule_row = res;
          this.load_matches = false;
        })
    },
    yearActive(newVal) {
      this.getLastMatches()
        .then(res => {
          this.schedule_row = res;
          this.load_matches = false;
        })
    }
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Segoe, Tahoma, Geneva, sans-serif;
}

*::-webkit-scrollbar {
  width: 5px;
  /* ширина scrollbar */
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #8080801a;
  /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--mainColor);
  /* цвет плашки */
  border-radius: 20px;
  /* закругления плашки */
}

.app {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 80px - 41px);
  overflow: hidden;
  min-width: 330px;
  position: relative;
}

/* Нужно для нормального перемещения времени на странице игры */
.app.gamePage {
  position: unset;
}




.fade-enter-active,
.fade-leave-active {
  transition: all .3s;
  position: absolute;
  width: 100%;
  left: 0;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  position: absolute;
}



.height30-slide-enter-active,
.height30-slide-leave-active {
  transition: all 1s;
  height: 30px;
  opacity: 1;
}

.height30-slide-enter-from,
.height30-slide-leave-to {
  height: 0;
  opacity: 0;
}


.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.btn-entry {
  height: 40px;
}

h3 {
  margin-bottom: 20px;
}

h6.error {
  color: red;
  text-align: center;
}

.global-loading {
  position: absolute;
  left: 0;
  right: 0;
}

.loading-entry {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
  font-weight: bold;
  -webkit-animation: blink1 3s linear infinite;
  animation: blink1 3s linear infinite;
}

.loading-entry img {
  width: 90px;
  margin-bottom: 30px;
}


@-webkit-keyframes blink1 {
  0% {
    color: rgb(255 153 0);
  }

  50% {
    color: rgb(34 34 34);
  }

  100% {
    color: rgb(63 145 226);
  }
}

@keyframes blink1 {
  0% {
    color: rgb(255 153 0);
  }

  50% {
    color: rgb(34 34 34);
  }

  100% {
    color: rgb(63 145 226);
  }
}

.alert {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 230px;
  padding: 10px;
  z-index: 999;
  text-align: start;

}

.alert div:hover {
  opacity: .8;
}

.alert div {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all .3s;
}

.alert .success {
  background: rgb(235 235 235);
  border: 2px solid rgba(26, 255, 0, 0.4);
}

.alert .error {
  background: rgb(235 235 235);
  border: 2px solid rgba(255, 0, 0, 0.4);
}

.alert.noAlerts {
  pointer-events: none;
}

.schedule_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-top: -10px;
  height: 80px;
  margin-bottom: 20px;
  scroll-behavior: smooth;
  position: relative;
  overflow: auto;
}

.schedule_row_match {
  display: flex;
  min-width: 150px;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgb(100 100 111 / 80%) 0px 0px 1px 0px;
  border-radius: 5px;
  margin: 0 5px;
  height: 40px;
  transition: all .3s;
  cursor: pointer;
}

.schedule_row_match_img {
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
  border-radius: 70px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition: all .3s;
  background-color: white;
}

.match_online,
.match_online .schedule_row_match_img {
  box-shadow: red 0px 0px 3px 0px;
}

.schedule_row_match_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.schedule_row_match_score {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 21px;
}

.schedule_row_match_img_elem {
  width: 72px;
  height: 60px;
  overflow: hidden;
  transform: rotate(315deg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-move,
/* apply transition to moving elements */
.schedule-enter-active,
.schedule-leave-active {
  transition: all 0.5s ease;
}

.schedule-enter-from,
.schedule-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.schedule-leave-active {
  position: absolute;
}

.schedule_row_match_img_elem img {
  width: 100%;
  transform: rotate(45deg);
}


.schedule_row_match_img .schedule_row_match_img_elem:nth-of-type(1) {
  margin-left: -21px;
  margin-top: -4px;
  border-bottom: 1px solid var(--mainColor);
  z-index: 5;
}


.schedule_row_match_img .schedule_row_match_img_elem:nth-of-type(2) {
  margin-left: 2px;
  margin-top: -10px;
}

.schedule_row_match:hover,
.schedule_row_match:hover .schedule_row_match_img {
  box-shadow: var(--mainColor) 0px 0px 5px 1px;
}

.schedule_row.load_matches {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: -100px;
}

.schedule_row.load_matches img {
  width: 35px;
}

.schedule_row_arrow.left {
  left: 0;
}

.schedule_row_arrow.right {
  right: 0;
}

.schedule_row_arrow {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  background: white;
  z-index: 10;
}

.schedule_row_arrow.hidden {
  display: none !important;
}

.schedule_row_arrow svg {
  fill: black;
  width: 30px;
  cursor: pointer;
}

@media (max-width: 800px) {
  .schedule_row {
    overflow: auto;
  }

  .schedule_row_arrow {
    display: none;
  }
}

.team_loser {
  color: gray;
}

.description_block_admin {
  padding: 10px 10px;
  max-width: 300px;
  border-radius: 5px;
  color: black;
  margin: 10px 0;
  font-size: 13px;
  text-align: start;
  box-shadow: #c9e9ca 0px 0px 2px 0px;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  border: 2px solid #cbcbcb;
}

.description_block_admin span:nth-of-type(1) {
  margin-bottom: 10px;
  font-weight: bold;
  color: #9f9f9f;
}

.iconsvg {
  width: 15px;
  height: 15px;
  /* fill: white; */
  transform: translate(0.5);
  margin-left: 5px;
}

thead tr:first-child {
  height: 40px;
}

table thead {
  background: #d3d3d3;
}


.table {
  overflow: auto;
  padding-bottom: 5px;
  margin-top: 10px;
  border-radius: 5px;
}

table {
  width: 100%;
  text-align: center;
  border-spacing: unset;
  border-radius: 5px;
  white-space: nowrap;
}


.modalConfirmBackground {
  background-color: #000000ad;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modalConfirm {
  background-color: white;
  max-width: 400px;
  width: 90%;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalConfirmButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modalConfirmButtons button {
  width: 45%;
}

.modalConfirmHeader {
  margin-bottom: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.modalConfirmButtons button:nth-of-type(1) {
  background-color: var(--negativeColor);
  border: 1px solid var(--negativeColor);
  color: var(--negativeColorText);
}

.modalConfirmButtons button:nth-of-type(1):hover {
  opacity: .5;
}

button.buttonSettings {
  width: auto;
  padding: 5px;
}

.elementOpacity {
  opacity: .5;
  pointer-events: none;
}

button.negativeBtn {
  padding: 0px 5px;
  height: 30px;
  width: 100px;
  background-color: var(--negativeColor);
  border-color: var(--negativeColor);
}

button.negativeBtn:hover {
  background-color: var(--negativeColor);
  border-color: var(--negativeColor);
  opacity: .5;
}

.settingsActionsTable {
  max-height: 400px;
  overflow: auto;
  width: 100%;
}

.settingsActionsTable tbody tr {
  background-color: #ededed;
  border-bottom: 1px solid;
}

.settingsActionsTable tbody tr td {
  padding: 5px 0px;
}

/* Нужен для контейнеров, где transition выпускает за пределы блока элемент при появлении */
.relativeBlock {
  position: relative;
}


.null {
  text-align: center;
  padding: 15px;
  color: #b3b3b3;
  width: 100%;
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.null hr {
  width: 100%;
  margin-top: 40px;
  opacity: .3;
}
</style>