<template>
    <div class="mainCheckbox">
        <label class="mainCheckboxLabel" :class="{ mainCheckboxLabelChecked: modelValue }">
            <input type="checkbox" ref="mainCheckbox" :value="modelValue" @change="changeCheckbox" />
        </label>
        <span class="mainCheckboxHeader" v-if="header">{{ header }}</span>
    </div>
</template>

<script>
export default {
    name: 'MainCheckbox',
    props: {
        modelValue: [Number, String, Boolean],
        header: [String, null],
    },
    methods: {
        changeCheckbox(event) {
            if (event.target.value == 0) this.$emit('update:modelValue', 1);
            if (event.target.value == 1) this.$emit('update:modelValue', 0);
            this.$emit('checkboxUpdate');
        },
    }
}
</script>

<style scoped>
.mainCheckboxLabel input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.mainCheckboxLabel {
    cursor: pointer;
    width: 40px;
    min-width: 40px;
    height: 20px;
    background: #f0f0f0;
    border-radius: 5px;
    position: relative;
    margin-right: 5px;
}

.mainCheckboxLabel:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.mainCheckboxLabelChecked {
    background: var(--mainColor);
}

.mainCheckboxLabelChecked:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.mainCheckbox label:active:after {
    width: 30px;
}

.mainCheckbox {
    display: flex;
    margin: 5px 0;
    align-items: center;
    width: auto;
}

.mainCheckboxHeader {
    font-size: 12px;
    font-weight: 500;
}
</style>