import MainButton from "@/components/UI/MainButton";
import MainInput from "@/components/UI/MainInput";
import LoadingDownload from "@/components/UI/LoadingDownload";
import Seasons from "@/components/UI/Seasons";
import ModalEntry from "@/components/UI/ModalEntry";
import MainSelect from "@/components/UI/MainSelect";
import SelectPeriod from "@/components/UI/SelectPeriod";
import Divisions from "@/components/UI/Divisions";
import MainCheckbox from "@/components/UI/MainCheckbox";
import Stages from "@/components/UI/Stages";
import MainModal from "@/components/UI/MainModal";

export default {
    MainButton,
    MainInput,
    LoadingDownload,
    Seasons,
    ModalEntry,
    MainSelect,
    SelectPeriod,
    Divisions,
    MainCheckbox,
    Stages,
    MainModal,
}
