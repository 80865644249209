<template>
  <div class="main_select" @click="changeBool('open')">

    <div class="main_select_selected">
      <span class="main_select_selected_small">Статистика за</span><span class="main_select_selected_text">{{
        getGamePeriod(selected) }}</span>
    </div>

    <transition name="fade">
      <div v-if="open" class="main_select_modal">
        <div @click="selectPeriod(0)" :class="{ main_select_active: selected == 0 }">Весь матч</div>
        <div @click="selectPeriod(elem)" v-for="elem in list" :class="{ main_select_active: selected == elem }">
          {{ getGamePeriod(elem) }}
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: 'SelectPeriod',
  props: {
    list: Number,
    selected: [String, Number],
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    getGamePeriod(period) {
      if (Number(period) > 4) {
        return (Number(period) - 4) + " овертайм";
      } else if (period == 0) {
        return 'Весь матч';
      } else {
        return period + " период";
      }
    },
    selectPeriod(period) {
      this.$emit('selectPeriod', period)
    },
    changeBool(value) {
      if (this[value]) {
        this[value] = false;
      } else {
        this[value] = true;
      }
    },
  }
}
</script>

<style scoped>
.main_select {
  position: relative;
  height: 25px;
  width: 170px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  cursor: pointer;
  padding-right: 8px;
}

.main_select_modal {
  position: absolute;
  top: 24px;
  background-color: white;
  z-index: 99;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(100 100 111 / 20%) 0px 2px 3px 0px;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
}

.main_select_active {
  background-color: var(--mainColor);
  color: white;
  font-weight: bold;
}

.main_select_modal div {
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.main_select_selected_small {
  font-size: 10px;
  margin-right: 10px;
  color: gray;
}

.main_select_selected {
  display: flex;
  width: auto;
  padding: 0px 10px;
  align-items: flex-end;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .main_select {
    width: 100%;
    justify-content: center;
  }
}

.main_select_selected_text {
  color: var(--mainColor);
}
</style>
