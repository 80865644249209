<template>
    <div class="loading">
        <img v-if="!stopLoading" src="@/img/download.gif" alt="Загрузка">
        <span :class="{ animationOff: stopLoading }">{{ this.loadingStatus }}</span>
    </div>
</template>
<script>

import { mapState } from "vuex";

export default {
    name: 'LoadingDownload',

    computed: {
        ...mapState({
            loadingStatus: state => state.post.loadingStatus,
            stopLoading: state => state.post.stopLoading,
        }),
    }
}
</script>
<style scoped>
.loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    flex-direction: column;
    position: absolute;
    z-index: -1;
}

.loading img {
    width: 50px;
    height: 50px;
}

span {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    -webkit-animation: blink1 3s linear infinite;
    animation: blink1 3s linear infinite;
}

@-webkit-keyframes blink1 {
    0% {
        color: rgb(255 153 0);
    }

    50% {
        color: rgb(34 34 34);
    }

    100% {
        color: rgb(63 145 226);
    }
}

@keyframes blink1 {
    0% {
        color: rgb(255 153 0);
    }

    50% {
        color: rgb(34 34 34);
    }

    100% {
        color: rgb(63 145 226);
    }
}

span.animationOff {
    animation: none;
}
</style>