<template>
    <input :value="modelValue" @input="updateInput" type="text">
</template>

<script>
export default {
    name: 'MainInput',
    props: {
        modelValue: [String, Number],
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value)
        }
    }
}
</script>

<style scoped>
input {
    width: 100%;
    border: 1px solid var(--additionColor);
    border-radius: 5px;
    padding: 15px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 20px;
}
</style>