<!-- 

Заготовка на использование:
selected - выбранный индекс из массива
change - можно не использовать, изменяемый параметр
objectParam1

<MainSelect :selected="requestTeam.teamInfo.divisionIndex" :change="'requestTeam.teamInfo.division'"
            :objectParam1="'Дивизион'" :array_list="this.divisions" :header="'Дивизион'" v-on:select="selectDivision">
</MainSelect>
 -->

<template>
  <div class="main_select" @click="changeBool('open')">
    <div class="main_select_selected">
      <span class="main_select_selected_small" v-if="header">{{ header }}</span>
      <span class="main_select_selected_small" v-else="header">{{ array_list[selected][headerParam] }}</span>
      <span class="main_select_selected_text" v-if="objectParam1 && (selected || selected == 0)">

        <span v-if="array_list[selected]">
          {{ array_list[selected][objectParam1] }}
        </span>
        <span v-else>
          Не выбрано
        </span>

      </span>
      <span class="main_select_selected_text" v-else-if="selected">
        {{ array_list[selected] }}
      </span>
    </div>

    <transition name="fade">
      <div v-if="open" class="main_select_modal">
        <div @click="select(index)" v-for="(elem, index) in array_list"
          :class="{ main_select_active: selected == index }">
          <span v-if="objectParam1">{{ elem[objectParam1] }}</span>
          <span v-else> {{ elem }}</span>
          <span v-if="objectParam2"> № {{ elem[objectParam2] }}</span>
          <!-- <span class="activeStage" v-if="getActiveStage && elem.active">Активный</span> -->
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
export default {
  name: 'MainSelect',
  props: {
    array_list: [Array, Object],
    selected: [String, Number],
    change: [String],
    header: [String], // Описание селекта
    headerParam: [String], // Если описание нужно взять из массива
    objectParam1: [String, Boolean], // Основной параметр (Имя игрока)
    objectParam2: [String, Boolean], // Номер игрока
    objectParam3: [String, Boolean],
    objectParam4: [String, Boolean],
    objectParam5: [String, Boolean],
    getActiveStage: Boolean,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    select(value) {
      this.$emit('select', { param: this.change, value: value })
    },
    changeBool(value) {
      if (this[value]) {
        this[value] = false;
      } else {
        this[value] = true;
      }
    },
  }
}
</script>

<style scoped>
.main_select {
  position: relative;
  height: 25px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  width: auto;
  min-width: 120px;
  border: 2px solid transparent;
  padding: 16px 0;
  margin-right: 10px;
  transition: all .3s;
}
.main_select:hover {
  box-shadow: var(--additionColor) 0px 0px 0px 2px;
}

.main_select_selected_text {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .main_select {
    padding: 16px 0;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.main_select_modal {
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 99;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(100 100 111 / 20%) 0px 2px 3px 0px;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
}

.main_select_active {
  background-color: var(--mainColor);
  color: white;
  font-weight: bold;
}

.main_select_modal div {
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  height: 40px;
  display: flex;
  transition: all .3s;
  border: 1px solid #00000014;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
}

.main_select_modal div:hover {
  background-color: #efefef;
  color: var(--mainColor);
}

.main_select_selected_small {
  font-size: 13px;
  margin-right: 10px;
  color: gray;
  white-space: nowrap;
}

.main_select_selected {
  display: flex;
  width: 100%;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .main_select {
    width: 100%;
    justify-content: center;
  }
}

.main_select_selected_text {
  color: var(--mainColor);
}

.main_select_selected_text {
  font-size: 14px;
}

.activeStage {
  font-size: 14px;
  margin-left: 10px;
  position: absolute;
  right: 0px;
  top: -3px;
  opacity: .2;
}
</style>
