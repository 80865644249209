<template>
    <MainSelect v-if="stagesList.length && this.settings.showStages" :selected="stageIndexActive" :objectParam1="'name'"
        :array_list="stagesList" :getActiveStage="true" :headerParam="`typeName`" v-on:select="selectStage">
    </MainSelect>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
    name: 'Stages',
    props: {
        class: String,
    },
    emits: ["changeStage"],
    data() {
        return {
            stagesList: [],
            stageIndexActive: 0,
        }
    },
    methods: {
        selectStage(obj) {
            this.stageIndexActive = obj.value;
            this.setStage(this.stagesList[obj.value]);
        },
        ...mapMutations({
            setStage: 'post/setStage',
        }),
        mainFunctionComponent() {
            this.stagesList = this.getStagesInDivision;
            for (let i in this.stagesList) {
                if (this.stage.ID == this.stagesList[i].ID) {
                    this.stageIndexActive = i;
                }
            }
        },
    },
    mounted() {
        this.mainFunctionComponent();
    },
    watch: {
        yearActive() {
            this.mainFunctionComponent();
        },
        division() {
            this.mainFunctionComponent();
        },
    },
    computed: {
        ...mapGetters({
            getStagesInDivision: 'post/getStagesInDivision',
        }),
        ...mapState({
            stage: state => state.post.stage,
            division: state => state.post.division,
            yearActive: state => state.post.yearActive,
            settings: state => state.post.settings,
        }),
    },
}
</script>
<style scoped></style>