<template>
    <div class="modal" v-if="show" @click="setModalStatus(false)">
        <div @click.stop class="modal-entry">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    name: 'ModalEntry',
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        ...mapMutations({
            setModalStatus: 'post/setModalStatus',
        }),
    },
}
</script>

<style scoped>
.modal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    z-index: 999;
}

.modal-entry {
    margin: auto;
    background: white;
    border-radius: 5px;
    height: 300px;
    display: flex;
    width: 290px;
    padding: 40px;
    max-width: 300px;
    align-items: center;
    justify-content: center;
}
</style>
