<template>
    <div class="footer">
        <div class="footer-contain">
            <div class="col-left">
                <img src="@/img/basketstat.png" alt="BasketStat Logo">
            </div>
            <div class="col-right">
  
            </div>
        </div>
    </div>
  </template>
  <script>
  export default {
  
  }
  </script>
  <style scoped>
  .footer {
    padding: 10px;
    width: 100%;
    background: #ebebeb;
    display: flex;
    bottom: 0;
  }
  
  .footer-contain {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .col-left img {
    width: 80px;
  }
  </style>