import { createApp } from 'vue';
import App from '@/App';
import components from '@/components/UI'
import router from '@/router';
import store from '@/store';


const app = createApp(App).use(router)

Object.keys(components).forEach((component) => {
  app.component(components[component].name, components[component])
})

app.use(store).mount('#app')
