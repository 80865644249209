<template>
    <MainSelect v-show="this.years.length" :selected="getYearIndex" :objectParam1="'Сезон'" :array_list="this.years"
        :header="`Сезон`" v-on:select="selectSeason">
    </MainSelect>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Seasons',
    methods: {
        async selectSeason(obj) {

            const newSeason = this.years[obj.value].СезонБД;

            // Проверяем есть ли в выбранном сезоне дивизион с такой же буквой, как и активный
            let existDivision = null;
            let existStage = null;

            for (let i in this.divisions) {
                if (this.divisions[i].СезонБД == newSeason && this.divisions[i].Дивизион == this.division) {
                    existDivision = this.divisions[i];
                }
            }

            if (existDivision) {
                // Если есть такой дивизион, то проверим и стадию
                for (let i in this.stages) {
                    if (this.stages[i].season == newSeason && this.stages[i].division == this.division && this.stages[i].stage == this.stage.stage) {
                        existStage = this.stages[i];
                    }
                }
            } else {
                // Если такого дивизиона нет, то устанавливаем дивизион самый первый и стадию
                this.setDivision('A');
            }

            // Если стадии нет, то устанавливаем активную стадию в дивизионе
            if (!existStage) {
                for (let i in this.stages) {
                    if (this.stages[i].season == newSeason && this.stages[i].division == this.division && this.stages[i].active == 1) {
                        this.setStage(this.stages[i]);
                    }
                }
            }

            // Устанавливаем выбранный сезон
            this.setYearsActive(this.years[obj.value].СезонБД)
        },
        ...mapMutations({
            setYearsActive: 'post/setYearsActive',
            setDivision: 'post/setDivision',
            setStage: 'post/setStage',
        })
    },
    computed: {
        ...mapState({
            yearActive: state => state.post.yearActive,
            years: state => state.post.years,
            stage: state => state.post.stage,
            stages: state => state.post.stages,
            division: state => state.post.division,
            divisions: state => state.post.divisions,
        }),
        getYearIndex() {
            for (let i in this.years) {
                if (this.years[i].СезонБД == this.yearActive) {
                    return i;
                };
            }
            this.setYearsActive(this.years[0].СезонБД);
            return 0;
        },
    },
}
</script>
<style scoped></style>