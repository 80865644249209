<template>
    <MainSelect v-show="this.divisionsInActiveYear.length && this.settings.Дивизионы" :selected="getDivisionIndex" :objectParam1="'Название'"
        :array_list="this.divisionsInActiveYear" :header="`Дивизион`" v-on:select="selectDivision">
    </MainSelect>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Divisions',
    methods: {
        async selectDivision(obj) {

            const newDivision = this.divisionsInActiveYear[obj.value].Дивизион;
            let activeStage = null;
            // Ищем активную стадию в выбранном дивизионе
            for (let i in this.stages) {
                if (this.stages[i].season == this.yearActive && this.stages[i].division == newDivision && this.stages[i].active == 1) {
                    activeStage = this.stages[i];
                }
            }
            // Устанавливаем активную стадию у дивизиона
            this.setStage(activeStage);
            // Устанавливаем выбранный дивизион
            this.setDivision(this.divisionsInActiveYear[obj.value].Дивизион)
        },
        ...mapMutations({
            setDivision: 'post/setDivision',
            setStage: 'post/setStage',
        })
    },
    computed: {
        divisionsInActiveYear() {
            return this.divisions.filter(
                (elem) => elem.СезонБД == this.yearActive
            )
        },
        ...mapState({
            yearActive: state => state.post.yearActive,
            division: state => state.post.division,
            divisions: state => state.post.divisions,
            stage: state => state.post.stage,
            stages: state => state.post.stages,
            settings: state => state.post.settings,
        }),
        getDivisionIndex() {
            for (let i in this.divisionsInActiveYear) {
                if (this.divisionsInActiveYear[i].Дивизион == this.division) {
                    return i;
                };
            }
            // this.setDivision(this.divisionsInActiveYear[0].Дивизион);
            // return 0;
        },
    },
}
</script>
<style scoped></style>