<template>
    <div class="MainModalBackground" @click="closeModal">
        <div class="MainModal" @click.stop>
            <div class="MainModalClose" @click="closeModal">✖</div>
            <div class="MainModalCloseHeader">{{ header }}</div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: { header: String, },
    name: 'MainModal',
    methods: {
        closeModal() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style scoped>
.MainModalBackground {
    background-color: #000000ad;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainModal {
    background: white;
    border-radius: 5px;
    width: 90%;
    max-width: 400px;
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.MainModalClose {
    position: absolute;
    right: 12px;
    top: 5px;
    font-weight: bold;
    font-size: 22px;
    transition: all .3s;
    cursor: pointer;
}

.MainModalClose:hover {
    opacity: .5;
}

.MainModalCloseHeader {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 17px;
}
</style>