<template>
    <button>
        <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    name: 'MainButton'
  }
  </script>
  
  <style scoped>
  button {
    border: 1px solid var(--mainColor);
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background: var(--mainColor);
    color: white;
    transition: .3s;
    width: 100%;
    cursor: pointer;
  }
  
  button:hover {
    border: 1px solid var(--additionColor);
    background: var(--additionColor);
    color: white;
  }
  </style>